import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const InAppPageView = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: var(--on-secondary);
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 4rem;
`;

export const Wordmark = styled(RMWordmark)`
  width: 7rem;
`;
