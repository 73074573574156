import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { RementoPage } from '@/modules/routing';

import { Header, InAppPageView, Wordmark } from './InAppPage.styles';

function InApp() {
  const [pageParams] = useSearchParams();
  const link = pageParams.get('redirect') ?? window.location.href;

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(link);
      toast('Link copied successfully!', 'root-toast', 'success');
    } catch (error) {
      toast(
        'Unable to copy the link automatically. Please copy it manually from the input above.',
        'root-toast',
        'error',
      );
    }
  }, [link]);

  return (
    <InAppPageView>
      <Header>
        <Wordmark color="neutral" />
      </Header>
      <RMDialog.Root open>
        <RMDialog.Content>
          <RMDialog.Header title="Switch to a Different Browser" />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              Remento is not accessible using your current browser. Copy and paste this link directly in a browser such
              as Chrome or Safari.
            </RMText>
            <RMSpacer spacing="2xl" direction="column" />
            <RMInput id="link" value={link} readOnly />
          </RMDialog.Body>
          <RMDialog.Footer>
            <RMButton background="primary" leftIcon={faLink} fullWidth onClick={handleCopy}>
              Copy link
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </InAppPageView>
  );
}

export function InAppPage() {
  return (
    <RementoPage type="default">
      <InApp />
    </RementoPage>
  );
}
